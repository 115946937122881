import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import { Button } from '../components/ButtonElements';
import { 
    InfoContainer2, 
    InfoWrapper2, 
    ButtonContainer, 
    RangeButton, 
    DrawButton, 
    Spinner, 
    CardNumber 
} from '../components/InfoSection/InfoElements';
import { InfoRow2 } from '../components/InfoSection/InfoElements';
import { BackgroundContainer, Overlay } from '../components/InfoSection/InfoElements'; // New import
import LogoImage from '../images/IMG-20250204-WA0000.jpg';

const Cards = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [randomNumber, setRandomNumber] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const handleRangeSelect = (id) => {
        setSelectedRange(id);
        setRandomNumber(null); // Reset the random number when a new range is selected
    };

    const handleDrawCard = () => {
        if (!selectedRange) return;

        setIsLoading(true);
        setTimeout(() => {
            const rangeValues = {
                refleksyjne: 40,
                archetypow: 20,
                rytualow: 10,
                intencji: 10
            };

            const randomNum = Math.floor(Math.random() * rangeValues[selectedRange]) + 1;
            setRandomNumber(randomNum);
            setIsLoading(false);
        }, 2000);
    };

    return (
        <>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <BackgroundContainer backgroundImage={LogoImage}>
                <Overlay>
                    <InfoContainer2>
                        <InfoWrapper2>
                            <InfoRow2>
                                <ButtonContainer>
                                    <RangeButton 
                                        onClick={() => handleRangeSelect("refleksyjne")}
                                        isSelected={selectedRange === "refleksyjne"}
                                    >
                                        Refleksyjne
                                    </RangeButton>
                                    <RangeButton 
                                        onClick={() => handleRangeSelect("archetypow")}
                                        isSelected={selectedRange === "archetypow"}
                                    >
                                        Archetypów
                                    </RangeButton>
                                    <RangeButton 
                                        onClick={() => handleRangeSelect("rytualow")}
                                        isSelected={selectedRange === "rytualow"}
                                    >
                                        Rytuałów
                                    </RangeButton>
                                    <RangeButton 
                                        onClick={() => handleRangeSelect("intencji")}
                                        isSelected={selectedRange === "intencji"}
                                    >
                                        Intencji
                                    </RangeButton>
                                </ButtonContainer>
                            </InfoRow2>
                            <InfoRow2>
                                <DrawButton
                                    onClick={handleDrawCard}
                                    disabled={!selectedRange || isLoading}
                                    isLoading={isLoading}
                                >
                                    {isLoading ? <Spinner /> : (selectedRange ? "Losuj kartę" : "Wybierz talię")}
                                </DrawButton>
                            </InfoRow2>
                            <InfoRow2>
                                {randomNumber && <CardNumber>{randomNumber}</CardNumber>}
                            </InfoRow2>
                        </InfoWrapper2>
                    </InfoContainer2>
                </Overlay>
            </BackgroundContainer>
            <Footer />
        </>
    );
};

export default Cards;
