import styled from "styled-components";

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#DCD5C9' : '#A1AC9E')};

    @media screen and (max-width: 768px) {
        padding: 100px 0px;
    }
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 560px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 24px 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }
`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }

`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: #690A62;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
    
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.div`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding-right: 0;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0px;
  background: #DCD5C9;


  .pagination {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;


    .page-item {
      margin: 0 5px;
    }

    .page-link {
      display: inline-block;
      padding: 5px 10px;
      border: 1px solid #690A62;
      background-color: ${({ active }) => (active ? '#690A62' : 'transparent')};
      color: ${({ active }) => (active ? '#fff' : '#690A62')};
      cursor: pointer;

      &:hover {
        background-color: ${({ active }) => (active ? '#690A62' : '#690A62')};
        color: ${({ active }) => (active ? '#fff' : '#fff')};
      }
    }
  }
`;

export const Loader = styled.div`
    display: grid;
    z-index: 1;
    height: 560px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 80px 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }
`