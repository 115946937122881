import React, { useState } from 'react';
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarMenu,
    SidebarLink,
    SidebarRoute,
    SidebarWrapper,
    SubMenu,
    SubMenuItem
} from './SidebarElements';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { animateScroll as scroll } from 'react-scroll';

const Sidebar = ({ isOpen, toggle }) => {
    const [isOfertaOpen, setIsOfertaOpen] = useState(false);
    const [isOmnieOpen, setIsOmnieOpen] = useState(false);
    const toggleOferta = () => {
        setIsOfertaOpen(!isOfertaOpen);
    };

    const toggleOmnie = () => {
        setIsOmnieOpen(!isOmnieOpen);
    };

    const toggleHome = () => {
        scroll.scrollTo(20);
        scroll.scrollToTop();
    };

    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate('/');
    }

    return (
<SidebarContainer isOpen={isOpen} onClick={(e) => {
    // Close sidebar only if clicking on background, not inside menu
    if (e.target === e.currentTarget) toggle();
}}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='/' onClick={toggleHome}>Strona główna</SidebarLink>
                    <SidebarRoute href='https://sklep.atenida.pl' target='_blank' onClick={toggle}>Sklep</SidebarRoute>
                    <SidebarLink to='/cards' onClick={toggleHome}>Karty</SidebarLink>
                    
                        {/* Oferta Dropdown */}
                        <SidebarLink as="div" onClick={(e) => {
                            e.stopPropagation(); // Prevent sidebar from closing
                            toggleOferta();
                        }}>
                            Oferta ▼
                        </SidebarLink>
                        <SubMenu isOpen={isOfertaOpen}>
                            <SubMenuItem to='/oferta/sesje' onClick={toggle}>Konsultacje Indywidualne</SubMenuItem>
                            <SubMenuItem to='/oferta/pakiety-masterclass' onClick={toggle}>Pakiety Masterclass</SubMenuItem>
                            <SubMenuItem to='/oferta/kursy' onClick={toggle}>Kursy</SubMenuItem>
                            <SubMenuItem to='/news' onClick={toggle}>Artykuły</SubMenuItem>
                        </SubMenu>
                    <SidebarLink to='/booking' onClick={toggleHome}>Terminarz</SidebarLink>
                    {/* Oferta Dropdown */}
                    <SidebarLink as="div" onClick={(e) => {
                            e.stopPropagation(); // Prevent sidebar from closing
                            toggleOmnie();
                        }}>
                            Atenida ▼
                        </SidebarLink>
                        <SubMenu isOpen={isOmnieOpen}>
                            <SubMenuItem to='/omnie' onClick={toggleHome}>O mnie</SubMenuItem>
                            <SubMenuItem to='/konsultacje' onClick={toggle}>Konsultacje</SubMenuItem>
                            <SubMenuItem to='/life-transition-coach' onClick={toggle}>Life-Transition</SubMenuItem>
                            <SubMenuItem to='/styl-pracy' onClick={toggle}>Metody</SubMenuItem>
                            <SubMenuItem to='/opinie' onClick={toggle}>Opinie</SubMenuItem>
                        </SubMenu>
                    <SidebarLink to='/kontakt' onClick={toggleHome}>Kontakt</SidebarLink>
                    {route === 'authenticated' ? (
                        <SidebarLink to='#' onClick={logOut}>Wyloguj</SidebarLink>
                    ) : (
                        <SidebarLink to='/login' onClick={toggleHome}>Zaloguj</SidebarLink>
                    )}
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;