import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Page2 from './pages/Page2';
import Kontakt from './pages/Kontakt';
import About from './pages/About';
import AdminPage from './pages/AdminPage';
import Coach from './pages/Coach';
import Help from './pages/Help';
import News from './pages/News';
import BookingPage from './pages/BookingPage';
import TimeSlots from './pages/TimeSlots';
import Confirm from './pages/Confirm';
import DeletePost from './pages/DeletePost';
import Opinie from './pages/Opinie';
import FullArticle from './pages/FullArticle';
import NewsEditPage from './pages/NewsEdit';
import OfferEditPage from './pages/OfferEdit';
import Offer from './pages/Offer';
import DeleteOffer from './pages/DeleteOffer';
import Privacy from './pages/Privacy';
import Cards from './pages/Cards';


import { RequireAdmin } from './components/RequireAuth';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { createBrowserHistory } from "history";

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

import SignInPage from './pages/signin';

import '@aws-amplify/ui-react/styles.css';

I18n.putVocabularies(translations);
I18n.setLanguage('pl');

const myTheme = {
  name: 'my-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          10: { value: '#690A62' },
          20: { value: '#690A62' },
          30: { value: '#690A62' },
          40: { value: '#690A62' },
          50: { value: '#690A62' },
          60: { value: '#690A62' },
          70: { value: '#690A62' },
          80: { value: '#690A62' },
          90: { value: '#4E4D4A' },
          100: { value: '#690A62' },
        }
      },
      background: {
        primary: { value: '#DCD5C9' },
        secondary: { value: '#A1AC9E' },
      },
      border: {
        primary: { value: '#690A62' },
      },
      font: {
        primary: { value: '#4E4D4A' },
        secondary: { value: '#4E4D4A' },
      },
    },
  }
};

export const appHistory = createBrowserHistory();

function App() {
  return (
    <>
    <AmplifyProvider theme={myTheme} >
    <Authenticator.Provider>
    <Router history={appHistory}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/booking" element=
          {<BookingPage />}/>
        <Route path="/timeslots" element={
          <RequireAdmin><TimeSlots /></RequireAdmin>
        } />
        <Route path="/login" element={<SignInPage />} />
        <Route path="/confirm" element={<RequireAdmin><Confirm /></RequireAdmin>} />
        <Route path="/konsultacje" element={<Page2 />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/styl-pracy" element={<Help />} />
        <Route path="/life-transition-coach" element={<Coach />} />
        <Route path="/omnie" element={<About />} />
        <Route path="/opinie" element={<Opinie />} />
        <Route path="/news" element={<News />} />
        <Route path="/post/:date/:hour" element={<FullArticle />} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/cards" element={<Cards/>} />
        <Route path="/oferta/:cat" element={<Offer />} />
        <Route path="/newsEdit" element={<RequireAdmin><NewsEditPage /></RequireAdmin>} />
        <Route path="/offerEdit" element={<RequireAdmin><OfferEditPage /></RequireAdmin>} />
        <Route path="/adminpage" element={<RequireAdmin><AdminPage /></RequireAdmin>} />
        <Route path="/deletepost/:date/:hour" element={
          <RequireAdmin><DeletePost /></RequireAdmin>
        } />
        <Route path="/deleteoffer/:deletename" element={
          <RequireAdmin><DeleteOffer /></RequireAdmin>
        } />
      </Routes>
    </Router>
    </Authenticator.Provider>
    </AmplifyProvider>
    </>
  );
}

export default App;
