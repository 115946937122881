import React, { useState, useEffect }from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, BtnWrap, ModalItems} from './OfferEditElements'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import 'react-quill/dist/quill.snow.css';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
  

const theme = createTheme({
    palette: {
      primary: {
        light: '#DCD5C9',
        main: '#A1AC9E',
        dark: '#690A62',
        contrastText: '#4E4D4A',
      },
      secondary: {
        light: '#A1AC9E',
        main: '#BEB3A0',
        dark: '#4E4D4A',
        contrastText: '#fff',
      },
    }
  });

const OfferEdit = () => {
    const [newName, setNewName] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [link, setLink] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
      };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const editName = queryParams.get('name');

    const isEdit = editName !== null;

    useEffect(() => {
      // Fetch the news data for editing based on the newsId
      if (isEdit) {
          async function fetchData(name) {
            const apiName = 'Atenida2';
            const path = `/offer/single?name=${name}`;
            const params = {
                headers: {},
            };

            try {
                const response = await API.get(apiName, path, params);
                setNewName(response.name);
                setCategory(response.category);
                setDescription(response.description);
                setPrice(response.price);
                setLink(response.link);
            } catch (error) {
                console.error(error);
            }
          }
        fetchData(editName);
        }
    }, [isEdit, editName]);


    const createNewNews = async () => {
        try {
            const apiName = 'Atenida';
            const path = '/offer';
            const myInit = {
                body: { 'name' : newName,
                        'category' : category,
                        'description' : description,
                        'price' : price,
                        'link' : link,
                        'image' : imageFile ? await readFileAsBase64(imageFile) : null,
                }, 
                headers: {} // OPTIONAL
            }
            console.log(imageFile);
        await API.post(apiName, path, myInit);
        setMessage('Confirmation successful!');
    
        } catch (error) {
          console.log('Error creating news', error);
          setMessage('Send failed ' + error);
        }
        setOpen(true);
      };

      const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImageFile(file);
      };

      const readFileAsBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });

        const handleUpdateNews = async () => {
          try {
            const apiName = 'Atenida';
            const path = `/offer?name=${newName}`; // Update the path with the newsId
            const myInit = {
              body: {
                newName,
                category,
                description,
                price,
                link,
              },
              headers: {} // OPTIONAL
            };
            await API.put(apiName, path, myInit);
            setMessage('Update successful!');
          } catch (error) {
            console.log('Error updating news', error);
            setMessage('Update failed ' + error);
          }
          setOpen(true);
        };
    
  return (
    <>
        <InfoContainer>
            <InfoWrapper>
            <ThemeProvider theme={theme}>
            <ModalItems>   
            <FormControl fullWidth variant="outlined">
            <InputLabel id="category-label">Kategoria</InputLabel>
            <Select
                labelId="category-label"
                value={category}
                onChange={handleCategoryChange}
                label="Kategoria"
            >
                <MenuItem value="Konsultacje Indywidualne">Konsultacje Indywidualne</MenuItem>
                <MenuItem value="Kursy">Kursy</MenuItem>
                <MenuItem value="Pakiety Masterclass">Pakiety Masterclass</MenuItem>
            </Select>
            </FormControl>
            </ModalItems>
            <ModalItems>   
            <TextField disabled={isEdit} id="outlined-basic" label="Nazwa" variant="outlined" fullWidth value={newName} 
                    onChange={(event) => {setNewName(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            <ModalItems>   
            <TextField id="outlined-basic" label="Opis" variant="outlined" fullWidth value={description} 
                    onChange={(event) => {setDescription(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            <ModalItems>   
            <TextField id="outlined-basic" label="Cena" variant="outlined" fullWidth value={price} 
                    onChange={(event) => {setPrice(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            <ModalItems>   
            <TextField id="outlined-basic" label="Link" variant="outlined" fullWidth value={link} 
                    onChange={(event) => {setLink(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            </ThemeProvider>
            {isEdit ? null : <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginBottom: '1rem' }}/>}
            
            <BtnWrap>
            <Button onClick={isEdit ? handleUpdateNews : createNewNews}>
              {isEdit ? 'UPDATE' : 'SEND'}
            </Button>
            </BtnWrap>
            <Collapse in={open}>
            <Alert
              action={
                  <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                      setOpen(false);
                  }}
                  >
                  <CloseIcon fontSize="inherit" />
                  </IconButton>
              }
              sx={{ mb: 2 }}
              >
              {message}
          </Alert>
        </Collapse>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default OfferEdit