import styled from "styled-components";

export const InfoContainer = styled.div`
    background: #DCD5C9;

    @media screen and (max-width: 768px) {
        padding: 100px 0px;
    }
`

export const InfoWrapper = styled.div`
    z-index: 1;
    min-height: 1000px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 80px 24px;
    justify-content: center;
    width: 900px;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }
    .ql-editor{
        min-height:200px;
    }
`

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: #690A62;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
    
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.div`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding-right: 0;
`
export const ModalItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    margin-bottom: 16px;
`;