import styled from "styled-components";


export const InfoContainer = styled.div`
  background: #a1ac9e;
  color: #fff;
  padding: 100px 0px; // Add padding for mobile screens
`

export const InfoWrapper = styled.div`
  z-index: 1;
  max-width: 1100px;
  margin: 0 auto;
  padding: 80px 24px;
  justify-content: center;
  width: 100%; // Change to 100% to make it responsive

  @media screen and (max-width: 768px) {
    /* Remove height property and add additional styling if required */
  }
`

export const TextWrapper = styled.div`
    max-width: 1000px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: #690A62;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

  @media screen and (max-width: 480px) {
    font-size: 32px; // Reduce the font size on smaller screens
  }
`;

export const Subtitle = styled.div`
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
    text-align: right;
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px
`

export const ImgWrap = styled.div`
`

export const Img = styled.img`
  width: 100%;
  height: auto; // Make the image responsive
  margin: 0px 0px 20px 0px;
  padding-right: 0;
  max-width: 555px; // Add a max-width to the image to prevent overflow

  @media screen and (max-width: 768px) {
    width: 100%; // Adjust the image width on smaller screens
  }
`;

export const Loader = styled.div`
    display: grid;
    z-index: 1;
    height: 560px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 80px 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }
`