import styled from "styled-components";
import {FaTimes} from "react-icons/fa";
import { Link as LinkR } from 'react-router-dom';


export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    max-width: 380px;
    height: 100%;
    background: #690A62;
    display: grid;
    align-items: center;
    top: 0;
    right: 0;
    transition: 1s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    right: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
    color: #BEB3A0;
`;

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

export const SidebarWrapper = styled.div`
    color: #BEB3A0;
`;

export const SidebarMenu = styled.ul`
    display: flex;
    flex-direction: column; /* Stack items vertically */
    text-align: center;
    gap: 10px; /* Adds spacing between items */

    @media screen and (max-width: 480px) {
        gap: 5px;
    }
`;

export const SidebarLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.4s ease-in-out;
    text-decoration: none;
    color: #BEB3A0;
    cursor: pointer;

    &:hover {
        color: #fff;
        transition: 0.4s ease-in-out;
    }
`;

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`;

export const SidebarRoute = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.4s ease-in-out;
    text-decoration: none;
    color: #BEB3A0;
    cursor: pointer;
    
    &:hover {
        color: #fff;
        transition: 0.4s ease-in-out;
    }
`;

export const SubMenu = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};  /* Hide or show */
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
`;

export const SubMenuItem = styled(LinkR)`
    display: block;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.4s ease-in-out;
    text-decoration: none;
    color: #BEB3A0;
    cursor: pointer;

    &:hover {
        color: #fff;
        transition: 0.4s ease-in-out;
    }
`;

