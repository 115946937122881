import styled from "styled-components";
import { Link } from "react-router-dom";

export const InfoContainer = styled.div`
    color: #fff;
    background: #A1AC9E;
    padding: 100px 0;

    @media screen and (max-width: 768px) {
        padding: 60px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 24px 24px;
    justify-content: center;
    gap: 24px;
    min-height: 400px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 24px;
    }

    @media screen and (min-width: 969px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const InfoRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TextWrapper = styled.div`
    max-width: 440px;
    padding: 0 16px;
    text-align: center;
`;

export const TopLine = styled.p`
    color: #690A62;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 8px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 8px;
    color: #BEB3A0;
    
    @media screen and (max-width: 480px) {
        font-size: 20px;
    }
`

export const PriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
`;

export const PriceValue = styled.span`
    color: #A1AC9E;
    text-align: right;
    font-size: 18px;
    margin-left: auto;
    margin-bottom: 16px;
`;

export const Subtitle = styled.div`
    margin-bottom: 16px;
    font-size: 18px;
    text-align: left;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`;

export const AdminBtnWrap = styled.div`
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding-right: 0;
`

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 16px;


  .pagination {
    display: inline-flex;
    list-style: none;
    padding: 0;
    margin-bottom: 20px;


    .page-item {
      margin: 0 5px;
    }

    .page-link {
      display: inline-block;
      padding: 5px 10px;
      border: 1px solid #690A62;
      background-color: ${({ active }) => (active ? '#690A62' : 'transparent')};
      color: ${({ active }) => (active ? '#fff' : '#690A62')};
      cursor: pointer;

      &:hover {
        background-color: ${({ active }) => (active ? '#690A62' : '#690A62')};
        color: ${({ active }) => (active ? '#fff' : '#fff')};
      }
    }
  }
`;

export const Loader = styled.div`
    display: grid;
    z-index: 1;
    height: 560px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 80px 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 1100px;
    }
`