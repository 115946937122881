import React, {useState, useEffect} from 'react'
import { 
    Wrapper, 
    RowWrapper, 
    ModelText, 
    ViewWrapper, 
    Title, 
    ColumnWrapper, 
    Appointments, 
    Appointment, 
    AppointmentTime, 
    AppointmentName,
    TitleWrap,
    ModalOverlay,
    ModalWrapper,
    CloseButton,
    ModalItems,
    ContentWrapper,
    DeleteButton,
    DeleteButtonWrapper,
    NoItems,
    NoAppointment,
} from './BookingElements'
import { Calendar as Cal } from 'react-calendar';
import './style.css';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { API } from 'aws-amplify';
import { Button } from "../ButtonElements";
import uuid from 'react-uuid';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    primary: {
      light: '#DCD5C9',
      main: '#A1AC9E',
      dark: '#690A62',
      contrastText: '#4E4D4A',
    },
    secondary: {
      light: '#A1AC9E',
      main: '#BEB3A0',
      dark: '#4E4D4A',
      contrastText: '#fff',
    },
  }
});



const Booking = () => {
    const [value, onChangeVal] = useState(new Date());
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedStart, setSelectedStart] = useState();
    const [selectedEnd, setSelectedEnd] = useState();
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [open, setOpen] = useState(false);

    const [imie, setImie] = useState('');
    const [nazwisko, setNazwisko] = useState('');
    const [email, setEmail] = useState('');
    const [pytanie1, setPytanie1] = useState('');
    const [pytanie2, setPytanie2] = useState('');
    const [pytanie3, setPytanie3] = useState('');
    const [pytanie4, setPytanie4] = useState('');
    const [pytanie5, setPytanie5] = useState('');
    const [pytanie6, setPytanie6] = useState('');

    const [allFieldsFilled, setAllFieldsFilled] = useState(false);

    const checkAllFieldsFilled = () => {
      if (imie && nazwisko && pytanie1 && pytanie2 && pytanie3 && pytanie4 && pytanie5 && pytanie6) {
        setAllFieldsFilled(true);
      } else {
        setAllFieldsFilled(false);
      }
    };


    const tileContent = ({ date, view }) => {
      if (view === 'month') {
        // Get the current date
        const currentDate = new Date();
    
        // Check if the date is in the future or today
        if (date > currentDate || (date.getFullYear() === currentDate.getFullYear() && date.getMonth() === currentDate.getMonth() && date.getDate() === currentDate.getDate())) {
          // Check if the date is in the appointments array
          const isInAppointments = appointments.some(appointment => {
            return (
              new Date(appointment.full_date).getDate() === date.getDate() &&
              new Date(appointment.full_date).getMonth() === date.getMonth() &&
              new Date(appointment.full_date).getFullYear() === date.getFullYear() &&
              new Date(appointment.full_date) > currentDate
            );
          });
    
          // If the date is in the future appointments array, return a div with a red background color
          if (isInAppointments) {
            return "react-calendar__tile--hasActive";
          }
        }
      }
    };

    const onDateChange = (valueNew) => {
        if (valueNew.getMonth() !== value.getMonth() || valueNew.getFullYear() !== value.getFullYear()) {
            onChangeVal(valueNew);
            setRefresh(!refresh);
        }else{
            onChangeVal(valueNew);
            setSelectedAppointmentsFunc(valueNew);
        }
    }

    const onBigDateChange = (event) => {
        const { action, activeStartDate, valueNew, view } = event;
        if (action === 'prev' || action === 'next' || action === 'prev2' || action === 'next2' || (action === 'drillDown' && view === 'month')) {
            if (activeStartDate.getMonth() !== value.getMonth() || activeStartDate.getFullYear() !== value.getFullYear()) {
                onChangeVal(activeStartDate);
                setRefresh(!refresh);
            }
        }
    }


    const setSelectedAppointmentsFunc = (value) => {
      setSelectedAppointments(
        appointments.filter(
          (appointment) =>
            new Date(appointment.full_date) > new Date() && // Filter out past events
            new Date(appointment.full_date).getDate() === value.getDate() &&
            new Date(appointment.full_date).getMonth() === value.getMonth() &&
            new Date(appointment.full_date).getFullYear() === value.getFullYear() &&
            appointment.client === ''
        )
      );
    }

    useEffect(() => {
      async function fetchData(value) {
        console.log("fetching new data!");
        console.log(value);
        const apiName = 'Atenida2';
        const path = '/slots';
        const params = {
          queryStringParameters: {
            month: value.getMonth() + 1,
            year: value.getFullYear(),
          },
          headers: {}
        };
        const response = await API.get(apiName, path, params);
        setAppointments(response);
        setSelectedAppointments(
          response.filter(
            (appointment) =>
            new Date(appointment.full_date).getDate() === value.getDate() && new Date(appointment.full_date).getMonth() === value.getMonth() && new Date(appointment.full_date).getFullYear() === value.getFullYear() && appointment.client === '')
        );
      }
      fetchData(value);
    }, [refresh]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const displayOutput = () => {
        setIsModalOpen(true);
      };

      const closeModal = () => {
        setIsModalOpen(false);
      };

    const sendEmail = async () => {
        const apiName = 'Atenida2';
        const path = '/send';
        const myInit = {
            body: { 'start' : selectedStart,
                    'end' : selectedEnd,
                    'date' : selectedDate,
                    'imie' : imie,
                    'nazwisko' : nazwisko,
                    'mail' : email,
                    'pytanie1' : pytanie1,
                    'pytanie2' : pytanie2,
                    'pytanie3' : pytanie3,
                    'pytanie4' : pytanie4,
                    'pytanie5' : pytanie5,
                    'pytanie6' : pytanie6,
        },
            headers: {} // OPTIONAL
        };
        const resp = await API.post(apiName, path, myInit);

        if (resp.ResponseMetadata.HTTPStatusCode === 200){
          setOpen(true);
          setIsModalOpen(false);
        }
    }

    useEffect(() => {
        setFilteredAppointments(appointments.filter(appointment => {
          return appointment.date === selectedDate && appointment.start_hour === selectedStart;
        }));
      }, [selectedDate, selectedStart, appointments]);

    const reserve = (date, start, end) => {
        setSelectedDate(date);
        setSelectedStart(start);
        setSelectedEnd(end);
        displayOutput();
    }

  return (
    <>
    <Wrapper id="calendar">
        <RowWrapper>
            <ViewWrapper>
                <ColumnWrapper>
                    <Cal onChange={onDateChange} value={value} tileClassName={tileContent} onActiveStartDateChange={onBigDateChange}/>
                </ColumnWrapper>
                <ColumnWrapper>
                {!open ? 
                  <Appointments>
                  <TitleWrap>
                      <Title>
                          {value.toLocaleDateString()}
                      </Title>
                  </TitleWrap>
                  {selectedAppointments.map((appointment) => {
                    const startDate = new Date(appointment.date + "T" + appointment.only_start + "Z");
                    const endDate = new Date(appointment.date + "T" + appointment.end_hour + "Z");

                    const startTime = startDate.toLocaleTimeString([], {timeZone: 'Europe/Warsaw', hour: '2-digit', minute:'2-digit'});
                    const endTime = endDate.toLocaleTimeString([], {timeZone: 'Europe/Warsaw', hour: '2-digit', minute:'2-digit'});
                  return (
                      <Appointment key={uuid()}>
                      <AppointmentTime>
                      {startTime}-{endTime}
                      </AppointmentTime>
                      <AppointmentName>{appointment.title}</AppointmentName>
                      <DeleteButtonWrapper>
                      {new Date(`${appointment.full_date}T${appointment.only_start}:00.000Z`) > new Date(new Date().getTime()+ 12 * 60 * 60 * 1000) ? <DeleteButton onClick={() => reserve(appointment.date, appointment.start_hour, appointment.end_hour)}>Umów</DeleteButton> : ''}
                      {/* <DeleteButton onClick={() => reserve(appointment.date, appointment.start_hour)}>Umów</DeleteButton> */}
                      </DeleteButtonWrapper>
                      </Appointment>
                  )})}
                  {selectedAppointments.length === 0 ? <NoAppointment><NoItems>Brak wolnych terminów w tym dniu</NoItems></NoAppointment> : null}
              </Appointments>
                : null}
                    <Collapse in={open}>
          <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                setRefresh(!refresh);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Rezerwacja została złożona! Poczekaj na potwierdzenie mailowe.
        </Alert>
      </Collapse>
                </ColumnWrapper>
            </ViewWrapper>
        </RowWrapper>
        {isModalOpen && (
        <Modal>
            <div className="modal-content">
                <ContentWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CloseButton onClick={closeModal}>&times;</CloseButton>
                    <Title>Nowa wizyta {value.toLocaleDateString()}</Title>
                        {filteredAppointments.length > 0 && filteredAppointments.map((appointment) => {
                          const startDate = new Date(appointment.date + "T" + appointment.only_start + "Z");
                          const endDate = new Date(appointment.date + "T" + appointment.end_hour + "Z");

                          const startTime = startDate.toLocaleTimeString([], {timeZone: 'Europe/Warsaw', hour: '2-digit', minute:'2-digit'});
                          const endTime = endDate.toLocaleTimeString([], {timeZone: 'Europe/Warsaw', hour: '2-digit', minute:'2-digit'});
                          return(
                            <div key={uuid()}>
                               <ModelText>Godzina: {startTime}-{endTime}</ModelText>
                               <ModelText>Nazwa spotkania: {appointment.title}</ModelText>
                            </div>
                        )})
                        }
                    <ThemeProvider theme={theme}>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Imię" variant="outlined" fullWidth value={imie} 
                    onChange={(event) => {setImie(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Nazwisko" variant="outlined" fullWidth value={nazwisko} 
                    onChange={(event) => {setNazwisko(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="E-mail" variant="outlined" fullWidth value={email}
                    onChange={(event) => {setEmail(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Ile masz lat?" variant="outlined" fullWidth value={pytanie1}
                    onChange={(event) => {setPytanie1(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Co Cię do mnie sprowadza? Nad czym chcesz ze mną pracować?" variant="outlined" fullWidth value={pytanie2}
                    onChange={(event) => {setPytanie2(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Co chcesz osiągnąć w obszarze, który Cię do mnie sprowadza? Jaki jest Twój cel?" variant="outlined" fullWidth value={pytanie3}
                    onChange={(event) => {setPytanie3(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Co musisz teraz zmienić, aby osiągnąć swój cel?" variant="outlined" fullWidth value={pytanie4}
                    onChange={(event) => {setPytanie4(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Jeśli pracowałaś/pracowałeś już kiedyś nad tym tematem to czego się nauczyłaś/nauczyłeś, co odkryłaś/odkryłeś?" variant="outlined" fullWidth value={pytanie5}
                    onChange={(event) => {setPytanie5(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Czego oczekujesz od naszej sesji?" variant="outlined" fullWidth value={pytanie6}
                    onChange={(event) => {setPytanie6(event.target.value);checkAllFieldsFilled();}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }}
                    />
                    </ModalItems>
                    </ThemeProvider>
                    <p>Przesyłając zgłoszenie akceptujesz <a href='/privacy' target='_blank'>Politykę prywatności</a></p>
                    {allFieldsFilled === true ? <Button onClick={sendEmail}>Rezerwuj</Button> : <Button>Wypełnij wszystkie pola</Button>}
                    </LocalizationProvider>
                </ContentWrapper>
            </div>
        </Modal>
        )}
    </Wrapper>
    </>
  )
}

const Modal = ({ children, closeModal }) => {
    return (
        <ModalOverlay>
            <ModalWrapper>
            {children}
            </ModalWrapper>
        </ModalOverlay>
    );
  }

export default Booking