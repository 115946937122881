import styled, { keyframes } from 'styled-components';

export const BackgroundContainer = styled.div`
    width: 100vw;
    height: 95vh;
    background: url(${({ backgroundImage }) => backgroundImage}) no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

export const Overlay = styled.div`
    width: 90%;
    max-width: 1100px;
    height: 80%;
    background: rgba(0, 0, 0, 0.8); // Semi-transparent black
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        width: 95%;
        height: 95%;
    }
`;

export const InfoContainer2 = styled.div`
    color: #fff;
    overflow-x: hidden; // Prevents horizontal scrolling
    width: 100vw; // Ensures it takes full screen width
    max-width: 100%;
    padding: 15px 0px;

    @media screen and (max-width: 768px) {
        padding: 10px 0px;
    }
`;

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#DCD5C9' : '#A1AC9E')};
    overflow-x: hidden; // Prevents horizontal scrolling
    width: 100vw; // Ensures it takes full screen width
    max-width: 100%;

    @media screen and (max-width: 768px) {
        padding: 100px 0px;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    min-height: 760px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 54px 24px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        min-height: 1100px;
    }
`

export const InfoWrapper2 = styled.div`
    display: grid;
    z-index: 1;
    min-height: 560px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 14px 14px;
    justify-content: center;

    @media screen and (max-width: 768px) {
        min-height: 700px;
    }
`

export const InfoRow = styled.div`
    display: grid;
    padding: 10px 0;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }

`

export const InfoRow2 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 15px 15px;
    grid-area: col1;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 15 15px;
    grid-area: col2;
`

export const TextWrapper = styled.div`
    max-width: 560px;
    padding-top: 30px;
    padding-bottom: 40px;
`

export const TopLine = styled.p`
    color: #690A62;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`

export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8fa' : '#010606')};
    
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#010606' : '#fff')};
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`

export const Img = styled.img`
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding-right: 20px;
    padding-left: 20px;
`

export const ImgRound = styled.img`
    width: 100%;
    margin: 0px 0px 20px 0px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5%;

`

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap; // Ensures buttons move to the next row on small screens
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%; // Prevents overflow
    padding: 0 10px; // Adds padding to prevent cutting off on the sides
`;

export const RangeButton = styled.button`
    flex: 1;
    min-width: 45%;
    padding: 15px 20px;
    font-size: 16px;
    color: #fff;
    background: ${({ isSelected }) => (isSelected ? '#690A62' : '#A1AC9E')}; // Change color when selected
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-align: center;
    height: 100px;

    &:hover {
        background: ${({ isSelected }) => (isSelected ? '#DCD5C9' : '#DCD5C9')}; // Slightly darker shade when hovered
    }

    &:disabled {
        background: #DCD5C9;
        cursor: not-allowed;
    }

    @media screen and (max-width: 768px) {
        min-width: 100%;
    }
`;


export const DrawButton = styled.button`
    padding: 10px 10px;
    font-size: 24px;
    color: #fff;
    background: ${({ disabled }) => (disabled ? '#DCD5C9' : '#690A62')};
    border: none;
    border-radius: 10px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: background 0.3s ease;
    position: relative;
    overflow: hidden;
    height: 100px; // Increased height
    width: 100%; // Fill the container width
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: ${({ disabled }) => (disabled ? '#DCD5C9' : '#DCD5C9')};
    }

    ${({ isLoading }) =>
        isLoading &&
        `
        color: transparent;
        background: #690A62;
        cursor: not-allowed;
    `}
`;

export const Spinner = styled.div`
    border: 8px solid #f3f3f3;
    border-top: 8px solid #690A62;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${spin} 1s linear infinite;
`;

export const CardNumber = styled.div`
    font-size: 48px;
    font-weight: bold;
    color: #690A62;
    text-align: center;
    margin-top: 2px;
    text-shadow: 
        -1px -1px 0px white,  
        1px -1px 0px white,  
        -1px 1px 0px white,  
        1px 1px 0px white;  // White outline
`;

