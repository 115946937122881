import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
// import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
    background: #DCD5C9;
    height: 100px;
    // margin-top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 1.2s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
`;

export const NavLogo = styled(LinkR)`
    color: #DCD5C9;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: lighter;
    text-decoration: none;
`;

export const NavIcon = styled.img`
    height: 100px;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute; 
        top: 0;
        right: 0;
        transform: translate(-120%, 90%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 100px;
    &.active {
        border-bottom: 3px solid #690A62;
    }
`;

export const NavLinks = styled(LinkR)`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #690A62;
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #690A62;
    }
`;

export const ExternalLink = styled.a`
    color: #000;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    &.active {
        border-bottom: 3px solid #690A62;
    }

    &:hover {
        transition: all 0.2s ease-in-out;
        color: #690A62;
    }
`;





